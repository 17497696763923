<template>
  <div>
    <b-card title="Client Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Client
                </th>
                <td>
                  {{ client.clientName }}
                </td>
              </tr>
              <tr v-if="client.firstJobStart">
                <th>First job started on</th>
                <td>
                  {{ client.firstJobStart | formatDateTimeTz(asup.timezone) }}
                  <feather-icon
                    v-b-popover.hover="'The actual date of the first SaveSet job of this client may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
              <tr v-if="client.lastJobStart">
                <th>Last job started on</th>
                <td>
                  {{ client.lastJobStart | formatDateTimeTz(asup.timezone) }}
                </td>
              </tr>
              <tr>
                <th>Jobs</th>
                <td>
                  {{ client.jobCount }}
                  <feather-icon
                    v-b-popover.hover="'The actual number of job of this client may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card
      :title="`Jobs (${orderedJobs.length})`"
    >
      <JobTable
        :jobs="orderedJobs"
        :time-range="timeRange"
        :timezone="asup.timezone"
        type="client"
      />
    </b-card>

    <b-card title="Protection Policies">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Protection Policy</th>
            <th>Backup jobs</th>
            <th>Replication jobs</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(group, index) in groups"
            :key="index"
          >
            <th>
              <b-link :to="{ name: 'ppdm-detail.protection-policy-detail', params: { name: group.name } }">
                {{ group.name }}
              </b-link>
            </th>
            <td>{{ group.backupJobCount }} <span v-if="group.backupJobFailedCount">({{ group.backupJobFailedCount }} failed)</span></td>
            <td>{{ group.replicationJobCount }} <span v-if="group.replicationJobFailedCount">({{ group.replicationJobFailedCount }} failed)</span></td>
          </tr>
        </tbody>
      </table>
    </b-card>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BLink,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'

import moment from '@/libs/moment'
import PpdmService from '@/service/ppdm.service'
import JobTable from './components/JobTable.vue'

export default {
  components: {
    BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, BLink, DateRangePicker, JobTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      client: {},
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      jobs: [],
    }
  },
  computed: {
    clientName() {
      return this.$route.params.clientName
    },
    orderedJobs() {
      return this.jobs.concat().sort((a, b) => (a.start > b.start ? -1 : 1))
    },
    groups() {
      return this.jobs.reduce((p, c) => {
        let group = p.find(x => x.name === c.protectionPolicyName)
        if (!group) {
          group = {
            name: c.protectionPolicyName,
            backupJobCount: 0,
            backupJobFailedCount: 0,
            replicationJobCount: 0,
            replicationJobFailedCount: 0,
          }

          p.push(group)
        }

        if (c.category === 0) {
          // eslint-disable-next-line no-param-reassign
          group.backupJobCount += 1
          if (group.status === 30) {
            group.backupJobFailedCount += 1
          }
        } else if (c.category === 1) {
          // eslint-disable-next-line no-param-reassign
          group.replicationJobCount += 1
          if (group.status === 30) {
            group.replicationJobFailedCount += 1
          }
        }

        return p
      }, [])
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getSummaryText(task) {
      if (task.result.summaries.length > 0) {
        return task.result.summaries.join('\n')
      }

      if (task.steps.length === 1) {
        return task.steps[0].name
      }
      if (task.steps.length > 1) {
        return task.steps.map(x => x.name).join(', ')
      }

      return null
    },
    toggleDetails(data) {
      // eslint-disable-next-line no-param-reassign
      this.$set(data, 'showDetails', !data.showDetails)
    },
    loadData() {
      PpdmService.getClientAsync(this.asup.id, this.clientName, { disableTenantFilter: true })
        .then(result => {
          this.client = result
        })

      PpdmService.getJobListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        client: this.clientName,
        onlyErrors: this.onlyErrors,
      }, { disableTenantFilter: true })
        .then(result => {
          // automatically show details for failed jobs
          for (let i = 0; i < result.items.length; i += 1) {
            if (result.items[i].status === 30) {
            // eslint-disable-next-line no-param-reassign
              result.items[i].showDetails = true
            }
          }

          this.jobs = result.items
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>

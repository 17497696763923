// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/ppdm', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/autosupport/ppdm/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerBuildAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post(`/autosupport/ppdm/${id}/build`, {}, requestConfig)
    return result.data
  },

  async triggerBuildAllAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post('/autosupport/ppdm/build', {}, requestConfig)
    return result.data
  },

  async getClientPrimaryMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${id}/client/primary-matrix`, requestConfig)
    return result.data
  },

  async getClientSecondaryMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${id}/client/secondary-matrix`, requestConfig)
    return result.data
  },

  async getProtectionPolicyListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${systemId}/protection-policy-job`, requestConfig)
    return result.data
  },

  async getProtectionPolicyJobKpiAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${systemId}/protection-policy-job/kpi`, requestConfig)
    return result.data
  },

  async getJobDurationListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${systemId}/job/duration`, requestConfig)
    return result.data
  },

  async getJobListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${systemId}/job`, requestConfig)
    return result.data
  },

  async getJobAsync(systemId, jobDisplayId, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${systemId}/job/${jobDisplayId}`, requestConfig)
    return result.data
  },

  async getClientAsync(systemId, client, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/ppdm/${systemId}/client/${encodeURIComponent(client)}`, requestConfig)
    return result.data
  },

}

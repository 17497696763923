<template>
  <div>
    <template v-if="steps.length > 0">
      <h3 class="mb-1">
        Steps
      </h3>

      <table
        class="table table-sm"
      >
        <thead>
          <tr>
            <th>Status</th>
            <th>Summary</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(step, index) in steps"
            :key="`step_${index}`"
          >
            <td>
              <JobStatusIcon
                :status="step.status"
              />
            </td>
            <td style="white-space: pre-wrap;">
              {{ step.name }}
            </td>
            <td>{{ step.durationText }}</td>
          </tr>
        </tbody>
      </table>
    </template>

    <template v-if="detailedSteps.length > 0">
      <h3 class="mt-1 mb-1">
        Detail Summary
      </h3>

      <table class="table table-sm">
        <tbody>
          <tr
            v-for="(step, index) in detailedSteps"
            :key="index"
          >
            <td style="white-space: pre-wrap;">
              {{ step }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'

export default {
  components: {
    JobStatusIcon,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  computed: {
    detailedSteps() {
      let result = []
      if (this.job.tasks.length > 0) {
        result = this.job.tasks
          .concat()
          .sort((a, b) => (a.start === null || a.start < b.start ? -1 : 1))
          .flatMap(x => x.result.summaries)
      }

      return result
    },
    steps() {
      let result = []
      if (this.job.tasks.length === 1 && this.job.tasks[0].steps.length > 0) {
        result = this.job.tasks[0].steps
          .concat()
          .map(x => ({
            start: x.start,
            status: x.calculatedStatus,
            name: x.name,
            durationText: x.durationInMillis !== null ? `${x.durationInMillis} ms` : null,
          }))
      } else if (this.job.tasks.length > 0) {
        result = this.job.tasks
          .concat()
          .map(x => ({
            start: x.start,
            status: x.status,
            name: x.name,
            durationText: this.$options.filters.formatClrTimeSpan(x.duration),
          }))
      }

      return result
        .sort((a, b) => (a.start === null || a.start < b.start ? -1 : 1))
    },
  },
}

</script>

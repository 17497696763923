<template>
  <div>
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Status</th>
            <th>
              <template v-if="type === 'client'">
                Protection policy
              </template>
              <template v-if="type === 'protection-policy'">
                Client
              </template>
            </th>
            <th>Asset</th>
            <th>Job</th>
            <th>Category</th>
            <th>Transferred</th>
            <th>Started on</th>
            <th>Ended on</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="job in jobs">
            <tr
              :key="job.id"
            >
              <td>
                <feather-icon
                  :icon="job.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  class="cursor-pointer float-left"
                  @click="toggleDetails(job)"
                />
                <JobStatusIcon :status="job.status" />
              </td>
              <td>
                <template v-if="type === 'client'">
                  <b-link :to="{ name: 'ppdm-detail.protection-policy-detail', params: { name: job.protectionPolicyName }, query: { from: $moment(timeRange.startDate).toISOString(), to: $moment(timeRange.endDate).toISOString()} }">
                    {{ job.protectionPolicyName }}
                  </b-link>
                </template>
                <template v-if="type === 'protection-policy'">
                  <b-link :to="{ name: 'ppdm-detail.client-detail', params: { clientName: job.clientName }, query: { from: $moment(timeRange.startDate).toISOString(), to: $moment(timeRange.endDate).toISOString()} }">
                    {{ job.clientName }}
                  </b-link>
                </template>
              </td>
              <td>
                {{ job.assetName }}
              </td>
              <td>
                <b-link :to="{ name: 'ppdm-detail.protection-policy-job-detail', params: { displayId: job.protectionPolicyDisplayId } }">
                  {{ job.protectionPolicyDisplayId }}
                </b-link>
              </td>
              <td>{{ job.categoryName }}<br><small class="text-muted">{{ job.subCategoryName }}</small></td>
              <td>{{ job.bytesTransferred | prettyBytes }}</td>
              <td>{{ job.start | formatDateTimeTz(timezone) }}</td>
              <td>{{ job.end | formatDateTimeTz(timezone) }}</td>
              <td>{{ job.duration | formatClrTimeSpan }}</td>
            </tr>
            <tr
              v-if="job.showDetails"
              :key="`${job.id}_detail`"
            >
              <td
                colspan="8"
              >
                <div class="row">
                  <div class="col-3">
                    <dl>
                      <dt>Started on</dt>
                      <dd>{{ job.start | formatDateTimeTz(timezone) }}</dd>

                      <dt>Ended on</dt>
                      <dd>{{ job.end | formatDateTimeTz(timezone) }}</dd>

                      <dt>Duration</dt>
                      <dd>{{ job.duration | formatClrTimeSpan }}</dd>

                      <dt>State</dt>
                      <dd>{{ job.state || '-' }}</dd>

                      <dt>Progress</dt>
                      <dd>{{ job.progress || '-' }}%</dd>

                      <template v-if="job.hostName">
                        <dt>Host</dt>
                        <dd>{{ job.hostName || '-' }}</dd>

                        <dt>Host Type</dt>
                        <dd>{{ job.hostType || '-' }}</dd>
                      </template>

                      <template v-if="job.assetName">
                        <dt>Asset</dt>
                        <dd>{{ job.assetName || '-' }}</dd>

                        <dt>Asset Type</dt>
                        <dd>{{ job.assetType || '-' }}</dd>
                      </template>

                      <template v-if="job.parserState">
                        <dt>Parser state</dt>
                        <dd>{{ job.parserState }}</dd>
                      </template>

                      <template v-if="job.parserNote">
                        <dt>Parser note</dt>
                        <dd>{{ job.parserNote }}</dd>
                      </template>

                    </dl>
                  </div>
                  <div class="col-3">
                    <template v-if="job.bytesTransferredThroughput">
                      <dt>Transferred Throughput</dt>
                      <dd>{{ job.bytesTransferredThroughput | prettyBytes }} / {{ job.bytesTransferredThroughputUnitOfTime }}</dd>
                    </template>

                    <template v-if="job.bytesTransferred">
                      <dt>Transferred</dt>
                      <dd>
                        {{ job.bytesTransferred | prettyBytes }}
                      </dd>
                    </template>

                    <template v-if="job.assetSizeInBytes">
                      <dt>Asset size</dt>
                      <dd>{{ job.assetSizeInBytes | prettyBytes }}</dd>
                    </template>

                    <template v-if="job.preCompBytes">
                      <dt>PreComp.</dt>
                      <dd>{{ job.preCompBytes | prettyBytes }}</dd>
                    </template>

                    <template v-if="job.postCompBytes">
                      <dt>PostComp.</dt>
                      <dd>{{ job.postCompBytes | prettyBytes }}</dd>
                    </template>
                  </div>
                  <div class="col-6">
                    <JobDetailTable :job="job" />
                  </div>
                </div>

                <div
                  v-for="(result, index) in getResultList(job)"
                  :key="index"
                  class="row"
                >
                  <div
                    v-if="result && (result.errorCode || result.errorReason || result.errorExtendedReason || result.errorDetailedDescription || result.errorRemediation || (result.status && result.status !== 'OK'))"
                    class="col"
                  >
                    <h3>
                      {{ result.resultClass }}
                    </h3>
                    <dl>
                      <template v-if="result.status">
                        <dt>Status</dt>
                        <dd>{{ result.status }}</dd>
                      </template>

                      <template v-if="result.errorCode">
                        <dt>Error Code</dt>
                        <dd>{{ result.errorCode }}</dd>
                      </template>

                      <template v-if="result.errorReason">
                        <dt>Reason</dt>
                        <dd>{{ result.errorReason }}</dd>
                      </template>

                      <template v-if="result.errorExtendedReason">
                        <dt>Extended Reason</dt>
                        <dd>{{ result.errorExtendedReason }}</dd>
                      </template>

                      <template v-if="result.errorDetailedDescription">
                        <dt>Detailed description</dt>
                        <dd>{{ result.errorDetailedDescription }}</dd>
                      </template>

                      <template v-if="result.errorRemediation">
                        <dt>Remediation</dt>
                        <dd>{{ result.errorRemediation }}</dd>
                      </template>
                    </dl>
                  </div>
                </div>

              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  VBPopover, BLink,
} from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import JobDetailTable from './JobDetailTable.vue'

export default {
  components: {
    BLink, JobStatusIcon, JobDetailTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    jobs: {
      type: Array,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ['protection-policy', 'client'].includes(value)
      },
    },
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),

  },
  methods: {
    toggleDetails(data) {
      // eslint-disable-next-line no-param-reassign
      this.$set(data, 'showDetails', !data.showDetails)
    },
    getResultList(job) {
      const results = []

      results.push({
        ...job.result,
        resultClass: 'Job Result',
      })

      job.tasks.forEach(task => {
        results.push({
          ...task.result,
          resultClass: 'Task Result',
        })
      })

      return results
    },
  },
}

</script>
